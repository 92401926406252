import axiosClient from '@/helper/call-center';

export const CartApi = {
  getMemberCart() {
    return axiosClient.get('/cart/member');
  },
  getGuestCart() {
    return axiosClient.get('/cart/guest');
  },
  deleteGuestCartItem(id: string) {
    return axiosClient.delete(`/cart/guest/by-id/${id}`);
  },
  deleteMemberCartItem(id: string) {
    return axiosClient.delete(`/cart/member/by-id/${id}`);
  },
};
