import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { notification } from 'antd';
import { useAuth } from '@/hooks/auth.hook';
import { CartApi } from '@/api-client/cart';

export const useCart = () => {
  const { profile } = useAuth();
  const queryClient = useQueryClient();

  // Member cart query
  const cartMemberQuery = useQuery({
    queryKey: ['cart-user'],
    queryFn: CartApi.getMemberCart,
    staleTime: 60,
    gcTime: 120,
    refetchOnWindowFocus: false,
    enabled: !!profile, // Enabled only if the user is authenticated
  });

  // Guest cart query
  const cartGuestQuery = useQuery({
    queryKey: ['cart-guest'],
    queryFn: CartApi.getGuestCart,
    staleTime: 60,
    gcTime: 120,
    refetchOnWindowFocus: false,
    enabled: !profile, // Enabled only if the user is a guest
  });

  // Mutation to delete a guest cart item
  const deleteCartGuestMutation = useMutation({
    mutationFn: CartApi.deleteGuestCartItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cart-guest'] });
    },
    onError: (err: any) => {
      notification.error({ message: 'Service', description: `Remove Fail - ${err}` });
    },
  });

  // Mutation to delete a member cart item
const deleteCartMemberMutation = useMutation({
    mutationFn: CartApi.deleteMemberCartItem, // Define this function in your CartApi
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cart-user'] }); // Invalidate member cart query to refresh data
    },
    onError: (err: any) => {
      notification.error({ message: 'Service', description: `Remove Fail - ${err}` });
    },
  });
  

  return {
    cartMemberQuery,
    cartGuestQuery,
    deleteCartGuestMutation,
    deleteCartMemberMutation
  };
};
