export const imgUrlTransfer = (url: string | undefined,src?: string) => {
  // If URL is not defined or is an empty string, return a default image
  if (!url) {
    return '/images/avatar_default.png'; // Return a placeholder or default image path
  }

  if (!url && src ==='avatar'){
    return '/images/avatar_default.png'; // Return a placeholder or default image path
  }

  // If the URL starts with https://, return it as is
  if (url.startsWith('https://')) {
    return url;
  }

  const backendDomain = process.env.NEXT_PUBLIC_BACKEND_DOMAIN; // Make sure to use the public environment variable

  // If backendDomain or url is missing, return a fallback image
  if (!backendDomain || !url) {
    return '/images/avatar_default.png'; // Return a placeholder or default image
  }

  // Return the complete URL by appending the backend domain to the relative URL
  return `${backendDomain}/${url}`;
};
