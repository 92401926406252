'use client';

import CartList from '@/components/elements/Cart/CartList';
import { useAuth } from '@/hooks/auth.hook';
import { useCart } from '@/hooks/cart.hook';
import { useWindowScroll } from '@/hooks/useWindowScroll';
import { MenuFoldOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';
import EmptyCart from '../../../../public/images/Cart/EmptyCart.webp';
import Cart from '../../../../public/images/CartImage.png';
import LogoHeader from '../../../../public/images/Common/HeaderLogo.webp';
import yellowStar from '../../../../public/images/yellowStar.png';
import { HomeAuthAction } from './auth-action';
import Menu from './Menu';
import Topbar from './Topbar';

interface IClientHeaderProps {
  className?: { root?: any };
}

const ClientHeader = (props: IClientHeaderProps) => {
  const [open, setOpen] = React.useState(false);
  const [cartOpen, setCartOpen] = React.useState(false);
  const [scroll] = useWindowScroll();
  const ref = React.useRef(null);
  const router = useRouter();
  const { cartMemberQuery, cartGuestQuery, deleteCartGuestMutation, deleteCartMemberMutation } = useCart();

  //Check active route
  const pathName = usePathname();
  const toggleMenu = () => {
    setOpen(!open); // Toggle between open and closed
  };
  const toggleCart = () => {
    setCartOpen(!cartOpen);
  };

  const activeStyle =
    'py-1 rounded-tl-none rounded-br-none rounded-tr-none rounded-bl-[12px] bg-[linear-gradient(270deg,_rgba(255,_255,_255,_0.00)_0%,_rgba(252,_220,_171,_0.50)_50%)] *:!text-zelene-red !text-zelene-red';

  //For Cart Feature
  const { profile } = useAuth();
  const listCart = profile ? cartMemberQuery?.data?.data : cartGuestQuery?.data?.data;
  const totalServicesCount = listCart?.length;
  const totalAddonsCount = listCart?.reduce((acc: any, item: any) => acc + item?.addons?.length, 0);
  const totalPrice = listCart?.reduce((total: any, service: any) => {
    const servicePrice = parseFloat(service?.service?.price);
    const addonsTotal = service?.addons?.reduce(
      (addonTotal: any, addon: any) => addonTotal + parseFloat(addon?.price),
      0,
    );
    return total + servicePrice + addonsTotal;
  }, 0);

  return (
    <div className='fixed !z-[999] mx-auto w-full' ref={ref}>
      <div className='z-[10] w-full'>
        <Topbar />{' '}
      </div>

      <div
        className={clsx(
          'fixed !z-[999] flex w-full flex-col justify-center gap-4 bg-opacity-50 py-3',
          scroll.y > 0 && 'shadow-sm backdrop-blur-[9px]',
          props.className?.root,
        )}
      >
        <div className='mx-auto w-[90%] lg:w-[80%]'>
          <div className='mx-auto flex w-[90%] justify-between sm:w-[100%]'>
            <Image
              src={LogoHeader}
              alt='form logo'
              width={84}
              height={91}
              className='h-[60px] w-[56px] cursor-pointer object-contain lg:h-[91px] lg:w-[84px]'
              onClick={() => router.push('/')}
            />

            {/* Navbar */}
            {/*  */}
            <div className='hidden !items-center !justify-center gap-4 *:text-[20px] *:font-medium *:leading-[24.42px] *:text-[#102647] lg:flex'>
              <Link href={'/'} className={clsx('flex items-center gap-1.5 px-3', pathName === '/' && activeStyle)}>
                <span className='!text-zele-red'>Spa</span>
                <Image alt='star footer' width={16} height={16} src={yellowStar} className='h-[16px] w-[16px]' />
              </Link>

              <Link
                href={'/booking'}
                className={clsx('flex items-center gap-1.5 px-3', pathName === '/booking' && activeStyle)}
              >
                <span className='!text-zele-red'>Booking</span>
                <Image alt='star footer' width={16} height={16} src={yellowStar} className='h-[16px] w-[16px]' />
              </Link>

              <Link className={clsx('px-3', pathName === '/gift' && activeStyle)} href={'/gift'}>
                Gift Card
              </Link>

              <Link className={clsx('px-3', pathName === '/services' && activeStyle)} href={'/services'}>
                Services
              </Link>
              <Link className={clsx('px-3', pathName === '/about' && activeStyle)} href={'/about'}>
                About
              </Link>
              <Link className={clsx('px-3', pathName === '/contact' && activeStyle)} href={'/contact'}>
                Contact
              </Link>

              <Link className={clsx('px-3', pathName === '/career' && activeStyle)} href={'/career'}>
                Career
              </Link>
            </div>
            {/* Auth Button / Action Button */}
            <div className='flex items-center gap-4 *:cursor-pointer'>
              <HomeAuthAction />
              <Image
                onClick={toggleCart}
                src={Cart}
                alt='cart icon'
                width={36}
                height={36}
                className='size-9 object-contain'
              />
              <MenuFoldOutlined className='block text-[20px] lg:!hidden' onClick={toggleMenu} />
            </div>

            <Drawer
              width={520}
              footer={
                listCart?.length > 0 ? (
                  <div className='flex justify-between'>
                    <div>
                      <div className='text-base font-medium leading-[20px] text-zelene-dark-blue'>
                        Total:&nbsp;
                        <span className='font-hneu text-[20px] font-bold text-zelene-red'>$ {totalPrice} </span>
                      </div>

                      <span className='text-sm font-medium leading-[20px] text-zelene-dark-blue sm:text-base'>
                        ({totalServicesCount} Services - {totalAddonsCount} Add ons)
                      </span>
                    </div>

                    <Link
                      href='/booking#top'
                      className={clsx(
                        'inline-flex items-center justify-center', // Makes it look like a button
                        'rounded-full bg-zelene-yellow px-2 font-hneu text-lg text-white sm:px-12 sm:py-2',
                        'transition-colors duration-300 ease-in-out hover:bg-yellow-500 hover:text-zelene-blue', // Optional hover state
                      )}
                      onClick={() => setCartOpen(false)}
                    >
                      <span className='mr-2'>Book Now</span>
                      {/* <Image alt="star footer" width={16} height={16} src={yellowStar} className="h-[16px] w-[16px]" /> */}
                    </Link>
                  </div>
                ) : (
                  <Link
                    href='/booking#top'
                    className={clsx(
                      'inline-flex w-full items-center justify-center !self-end', // Makes it look like a button
                      'rounded-full bg-zelene-yellow px-12 py-2 font-hneu text-lg text-white',
                      'transition-colors duration-300 ease-in-out hover:bg-yellow-500 hover:text-zelene-blue', // Optional hover state
                    )}
                    onClick={() => setCartOpen(false)}
                  >
                    <span className='mr-2'>Book Now</span>
                  </Link>
                )
              }
              title='Your cart'
              open={cartOpen}
              onClose={() => setCartOpen(false)}
            >
              {listCart?.length > 0 ? (
                <CartList
                  listCart={listCart}
                  onRemove={profile ? deleteCartMemberMutation.mutate : deleteCartGuestMutation.mutate}
                />
              ) : (
                <div className='flex h-full flex-col items-center justify-center font-hneu text-[32px] font-medium text-zelene-grey'>
                  Cart is empty !
                  <div className='relative h-[200px] w-full'>
                    <Image
                      alt='empty cart'
                      fill
                      sizes='(max-width: 640px) 80vw,20vw'
                      className='object-contain'
                      src={EmptyCart}
                    />
                  </div>
                </div>
              )}
            </Drawer>
            {/* Menu control */}
            <Drawer
              styles={{
                body: { backgroundColor: '#FCDCAB41', padding: 0, zIndex: 50, paddingTop: 64 },
                header: {
                  backgroundColor: '#FCDCAB41',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 17,
                },
              }}
              title={
                <div className='my-auto !flex min-h-10 flex-1 items-center !justify-end gap-2 pr-4'>
                  {/* <SearchOutlined onClick={() => setInputHidden(prev => !prev)} className='cursor-pointer text-[20px]' />
                <Input size='large' className='max-w-[200px]' hidden={inputHidden} placeholder='Search...' /> */}
                  <HomeAuthAction />
                </div>
              }
              width={'100vw'}
              onClose={() => setOpen(false)}
              open={open}
              placement='left'
            >
              <Menu setOpen={setOpen} />
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientHeader;
