import { CartListProps } from '@/interface/cart';
import React from 'react';
import CartItem from './CartItem';

const CartList: React.FC<CartListProps> = ({ listCart, onRemove }) => {
  return (
    <>
      {listCart?.map((cart: any) => (
        <CartItem showAddonBtn={true} key={cart._id} cart={cart} onRemove={() => onRemove(cart._id)} />
      ))}
    </>
  );
};

export default CartList;
