import dynamic from 'next/dynamic';
// import LocationDropdown from './LocationDropdown';
const LocationDropdown = dynamic(() => import('./LocationDropdown'), {
  ssr: false,
  loading: () => <div className='font-hneu text-white'>Location...</div>,
});

const Topbar = () => {
  return (
    <div className='relative !z-[999999] flex min-h-[45px] w-full items-center justify-end border-b border-[#ddd] bg-zelene-blue p-5 py-1'>
      <div className='flex items-center gap-2'>
        {/* <span className="text-sm text-white">Location:</span> */}

        {/* Location Dropdown */}
        {/* <Dropdown overlay={menu} trigger={['click']} overlayClassName="z-[99999999]">
          <span className="cursor-pointer text-sm font-medium text-white flex items-center relative z-[99999999]">
            Wallace, Australia <DownOutlined className="ml-2" />
          </span>
        </Dropdown> */}
        <LocationDropdown />
      </div>
    </div>
  );
};

export default Topbar;
