import { UserApi } from '@/api-client/user';
import { CustomerApi } from '@/api-client/user/customer';
import { IProfile } from '@/interface/auth';
import { EnumGender, UserRoleKey } from '@/utils/enum';
import { FormOutlined } from '@ant-design/icons';
import { Avatar, Input, Modal, notification, Radio } from 'antd';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';
import UpdatePassword from './update-password';

export interface IProfileInfoProps {
  isModalOpen: boolean;
  profile: IProfile;
  setOpenModal: (v: boolean) => void;
  mutateProfile: () => void;
  urlBack?: string;
}

export default function ProfileInfo(props: IProfileInfoProps) {
  const { isModalOpen, profile, setOpenModal, mutateProfile, urlBack } = props;
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [firstName, setFirstName] = useState<string>(profile?.first_name);
  const [lastName, setLastName] = useState<string>(profile?.last_name);
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState<EnumGender | null>(null);
  const pathName = usePathname();
  const [openUpdatePass, setOpenUpdatePass] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
    setFirstName('');
    setLastName('');
    setGender(null);
    setImage(null);
  };

  useEffect(() => {
    if (!profile || !isModalOpen) return;
    setFirstName(profile?.first_name);
    setLastName(profile?.last_name);
    setGender((profile.gender as EnumGender) || null);
  }, [profile, isModalOpen]);

  const avatarGenerator = useCallback(() => {
    if (isModalOpen) {
      if (image) {
        return URL.createObjectURL(image);
      } else {
        return profile?.avatar ? `/service/${profile?.avatar}` : '';
      }
    }
  }, [image, profile?.avatar, isModalOpen]);

  const handleUpdateProfile = useCallback(() => {
    if (loading) {
      return;
    }
    if (!firstName || (typeof firstName === 'string' && firstName.length === 0)) {
      notification.error({
        message: 'Please enter your name',
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('gender', gender || '');
    if (image) {
      formData.append('file', image, image.name);
    }
    if (profile.role !== UserRoleKey.CUSTOMER) {
      UserApi.updateProfile(formData)
        .then(() => {
          notification.success({
            message: 'Update successfully',
          });
          handleClose();
          mutateProfile();
        })
        .catch(error => {
          if (Array.isArray(error?.response?.data?.message)) {
            error?.response?.data?.message.forEach((item: any) => {
              notification.error({
                message: 'Update failed',
                description: item,
              });
            });
          } else {
            notification.error({
              message: 'Update failed',
              description: error?.response ? error.response.data?.message : error.message,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      CustomerApi.updateProfile(formData)
        .then(() => {
          notification.success({
            message: 'Update successfully',
          });
          handleClose();
          mutateProfile();
        })
        .catch(error => {
          if (Array.isArray(error?.response?.data?.message)) {
            error?.response?.data?.message.forEach((item: any) => {
              notification.error({
                message: 'Update failed',
                description: item,
              });
            });
          } else {
            notification.error({
              message: 'Update failed',
              description: error?.response ? error.response.data?.message : error.message,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [image, loading, firstName, handleClose, lastName, gender]);

  return (
    <Modal
      title={
        <div>
          <p className='mb-0 text-xl font-bold text-[#007DC0]'>Account information</p>
        </div>
      }
      open={isModalOpen}
      onOk={handleUpdateProfile}
      onCancel={handleClose}
      okText='Save'
      loading={loading}
    >
      <div className='pb-5'>
        <div style={{ gap: '24px', margin: '32px' }}>
          <div className='relative mx-auto flex justify-center'>
            <Avatar src={avatarGenerator()} size={90} style={{ cursor: 'pointer' }}>
              {profile?.first_name?.charAt(0).toUpperCase()}
            </Avatar>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                right: '42%',
                transform: 'translate(58%, 0)',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (fileRef?.current) {
                  fileRef.current.click();
                }
              }}
            >
              <div
                style={{
                  width: '32px',
                  height: '32px',
                  position: 'relative',
                }}
              >
                <Image src='/svgs/camera-rounded-icon.svg' fill alt='photo' />
              </div>
            </div>
            <input
              type='file'
              hidden
              ref={fileRef}
              onChange={e => {
                const { files } = e.target;

                if (files && files[0]) {
                  const file = files[0];

                  if (file?.type?.startsWith('image/')) {
                    setImage(file);
                  }
                }
              }}
              accept='image/*'
            />
          </div>

          <p
            style={{
              color: '#323232',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              textAlign: 'center',
              marginBottom: 0,
              fontFamily: 'Roboto',
              paddingTop: 5,
            }}
          >
            {profile.first_name + ' ' + profile.last_name}
          </p>
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex w-full border-b-2 py-1'>
            <div className='w-[40%]'>
              <p className='mb-0 text-sm text-gray-400'>First name</p>
            </div>
            <div className='flex w-[60%] justify-between'>
              <Input
                placeholder='Please enter fullname...'
                value={firstName}
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: '#000',
                  maxWidth: '80%',
                  padding: '0px 0px',
                }}
                variant='borderless'
                onChange={e => setFirstName(e.target.value)}
              />
              <FormOutlined style={{ fontSize: 20 }} />
            </div>
          </div>
          <div className='flex w-full border-b-2 py-1'>
            <div className='w-[40%]'>
              <p className='mb-0 text-sm text-gray-400'>Last name</p>
            </div>
            <div className='flex w-[60%] justify-between'>
              {/* <p className='mb-0 text-base font-semibold text-[#000]'>{profile.first_name + ' ' + profile.last_name}</p> */}
              <Input
                placeholder='Please enter fullname...'
                value={lastName}
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: '#000',
                  maxWidth: '80%',
                  padding: '0px 0px',
                }}
                variant='borderless'
                onChange={e => setLastName(e.target.value)}
              />
              <FormOutlined style={{ fontSize: 20 }} />
            </div>
          </div>
          <div className='flex w-full justify-between border-b-2 py-1'>
            <div className='w-[40%]'>
              <p className='mb-0 text-sm text-gray-400'>Password</p>
            </div>
            <div className='flex w-[60%] justify-between'>
              <p className='mb-0 text-xl font-semibold text-[#000]'>*********</p>
              <FormOutlined style={{ fontSize: 20, cursor: 'pointer' }} onClick={() => setOpenUpdatePass(true)} />
            </div>
          </div>
          <div className='flex w-full justify-between border-b-2 py-1'>
            <div className='w-[40%]'>
              <p className='mb-0 text-sm text-gray-400'>Gender</p>
            </div>
            <div className='flex w-[60%]'>
              <Radio.Group onChange={e => setGender(e.target.value)} value={gender}>
                <Radio value={EnumGender.MALE}>Male</Radio>
                <Radio value={EnumGender.FEMALE}>Female</Radio>
                <Radio value={EnumGender.OTHER}>Other</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className='flex w-full justify-between border-b-2 py-1'>
            <div className='w-[40%]'>
              <p className='mb-0 text-sm text-gray-400'>Phone</p>
            </div>
            <div className='flex w-[60%] justify-between'>
              <p className='mb-0 text-base font-semibold text-[#000]'>{profile.phone}</p>
              {/* <FormOutlined style={{ fontSize: 20, opacity: 0 }} /> */}
            </div>
          </div>
          <div className='flex w-full justify-between border-b-2 py-1'>
            <div className='w-[40%]'>
              <p className='mb-0 text-sm text-gray-400'>Email</p>
            </div>
            <div className='flex w-[60%] justify-between'>
              <p className='mb-0 text-base font-semibold text-[#000]'>{profile.email}</p>
              {/* <FormOutlined style={{ fontSize: 20, opacity: 0 }} /> */}
            </div>
          </div>
        </div>
      </div>
      <UpdatePassword
        isModalOpen={openUpdatePass}
        setOpenModal={setOpenUpdatePass}
        profile={profile}
        urlBack={urlBack}
      />
    </Modal>
  );
}
