import { useAuth } from '@/hooks/auth.hook';
import { AuthBtn } from './auth-btn';
import { ProfileBtn } from './profile-btn';

export const HomeAuthAction = () => {
  const { profile, firstLoading } = useAuth();

  if (firstLoading) {
    return (
      <div className='flex w-fit flex-row items-center gap-2 sm:w-40'>
        <span className='animate-pulse flex-1 rounded-sm bg-slate-200 px-10 py-2'></span>

        <div className='animate-pulse size-8 rounded-full bg-slate-200' />
      </div>
    );
  }

  return <div className='w-fit'>{profile ? <ProfileBtn /> : <AuthBtn />}</div>;
};
