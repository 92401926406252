export const validatePass = (pass: string, typePass: 'New password' | 'Old password' | 'Password') => {
  if (!pass || (typeof pass === 'string' && pass.length === 0)) {
    return 'Please enter your ' + typePass.toLowerCase();
  }
  if (
    !pass.match(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\[\]{}|;:,.<>?])[A-Za-z\d!@#$%^&*()_+\[\]{}|;:,.<>?]{8,}$/,
    )
  ) {
    return (
      typePass +
      ' must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character'
    );
  }
  return '';
};
