import Image from 'next/image';
import Link from 'next/link';
export const AuthBtn = () => {
  return (
    <Link href='/login' className='z-10 flex w-fit cursor-pointer flex-row items-center gap-2'>
      <span className='max-w-full flex-1 rounded-sm text-right text-lg font-semibold text-slate-800'>Login</span>

      <div className='relative size-8'>
        <Image src='/images/user-profile.png' alt='profile icon' sizes='32px' fill className='size-6 object-contain' />
      </div>
    </Link>
  );
};
