import axiosClient from '@/helper/call-center';

export const UserApi = {
  getAllStaff() {
    return axiosClient.get('/employee/staff');
  },
  getUserByPaginate(data: { current: number; limit: number; textSearch?: string; role?: string }) {
    return axiosClient.get('/employee/paginate', {
      params: data,
    });
  },
  getCountByRole(textSearch: string) {
    return axiosClient.get('/employee/count-by-role', {
      params: { textSearch },
    });
  },
  delete(id: string) {
    return axiosClient.delete(`/employee/${id}`);
  },
  updateProfile(formData: FormData) {
    return axiosClient.put('/employee/update-profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  updatePassword(data: { old_password: string; new_password: string; isLogoutAll: boolean }) {
    return axiosClient.put('/employee/update-password', data);
  },
};
