'use client';

import axiosClient from '@/helper/call-center';
import { FacebookOutlined, InstagramOutlined, PhoneOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Tooltip } from 'antd';
import clsx from 'clsx';

interface ISocialMediaProps {
  className?: {
    root: string;
    placement: any;
  };
}

const SocialMedia = (props: ISocialMediaProps) => {
  const { className } = props;

  const location = typeof window !== 'undefined' ? localStorage.getItem('location_id') : null;

  const userQuery = useQuery({
    queryKey: ['location-detail'],
    queryFn: async () => {
      try {
        const result: any = await axiosClient.get(`store-branch/${location}`);

        return result;
      } catch (error) {
        return [];
      }
    },
    refetchOnWindowFocus: false,
  });

  const telByShop: any = userQuery?.data?.data?.phone;
  
  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center gap-5 rounded-xl border-2 border-[#1D3F75] px-2.5 py-4 shadow-md *:cursor-pointer',
        className?.root,
      )}
    >
      <Tooltip title='Facebook' placement={className?.placement || 'left'}>
        <a
          href={
            'https://www.facebook.com/zelenespa/?paipv=0&eav=AfZgmYGmrAtqDCLUfx2nBNq1Zjm06cyWqFvrW66BpLROVpZZ2JJlV4kF1DZhQixu9NM&_rdr'
          }
          target='_blank'
          rel='noopener noreferrer'
        >
          <FacebookOutlined className='!text-[24px] !text-zelene-blue transition-transform hover:scale-150' />
        </a>
      </Tooltip>

      <Tooltip title='Instagram' placement={className?.placement || 'left'}>
        <a href={'https://www.instagram.com/zelenespa/'} target='_blank' rel='noopener noreferrer'>
          <InstagramOutlined className='!text-[24px] !text-zelene-blue transition-transform hover:scale-150' />
        </a>
      </Tooltip>

      <Tooltip title='Hotline' placement={className?.placement || 'left'}>
        <a href={`tel:${telByShop}`} target='_blank' rel='noopener noreferrer'>
          <PhoneOutlined className='!text-[24px] !text-zelene-blue transition-transform hover:scale-150' />
        </a>
      </Tooltip>
    </div>
  );
};

export default SocialMedia;
