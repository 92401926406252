import axiosClient from '@/helper/call-center';

export const CustomerApi = {
  getPaginate(data: { current: number; limit: number; textSearch?: string }) {
    return axiosClient.get('/customer/paginate', {
      params: data,
    });
  },
  delete(id: string) {
    return axiosClient.delete(`/customer/${id}`);
  },
  updateProfile(formData: FormData) {
    return axiosClient.put('/customer/update-profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  updatePassword(data: { old_password: string; new_password: string; isLogoutAll: boolean }) {
    return axiosClient.put('/customer/update-password', data);
  },
};
