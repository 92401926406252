import { UserApi } from '@/api-client/user';
import { CustomerApi } from '@/api-client/user/customer';
import { useRouter } from '@/hooks/useRouter';
import { IProfile } from '@/interface/auth';
import { UserRoleKey } from '@/utils/enum';
import { validatePass } from '@/utils/validate-pass';
import { Checkbox, Input, Modal, notification } from 'antd';
import { useState } from 'react';

export interface IUpdatePasswordProps {
  isModalOpen: boolean;
  setOpenModal: (v: boolean) => void;
  profile: IProfile;
  urlBack?: string;
}

export default function UpdatePassword(props: IUpdatePasswordProps) {
  const { isModalOpen, setOpenModal, profile, urlBack } = props;
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorOldPass, setErrorOldPass] = useState('');
  const [errorNewPass, setErrorNewPass] = useState('');
  const [errorConfirmPass, setErrorConfirmPass] = useState('');
  const router = useRouter();

  const handleClose = () => {
    setOpenModal(false);
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setErrorOldPass('');
    setErrorNewPass('');
    setErrorConfirmPass('');
  };
  const handleUpdateProfile = () => {
    if (loading) {
      return;
    }
    setErrorOldPass(validatePass(oldPassword, 'Old password'));
    setErrorNewPass(validatePass(newPassword, 'New password'));
    if (validatePass(oldPassword, 'Old password') || validatePass(newPassword, 'New password')) return;
    if (!confirmPassword || (typeof confirmPassword === 'string' && confirmPassword.length === 0)) {
      setErrorConfirmPass('Please enter your confirm password');
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorConfirmPass('Confirm password does not match');
      return;
    }
    setErrorConfirmPass('');
    setLoading(true);
    if (profile.role !== UserRoleKey.CUSTOMER) {
      UserApi.updatePassword({ old_password: oldPassword, new_password: newPassword, isLogoutAll: checked })
        .then(res => {
          notification.success({
            message: 'Update password successfully',
          });
          handleClose();
        })
        .catch(err => {
          notification.error({
            message: 'Update password failed',
            description: err?.response ? err.response.data?.message : err.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      CustomerApi.updatePassword({ old_password: oldPassword, new_password: newPassword, isLogoutAll: checked })
        .then(res => {
          notification.success({
            message: 'Update password successfully',
          });
          if (checked && urlBack) {
            router.push(urlBack);
          }
          handleClose();
        })
        .catch(err => {
          notification.error({
            message: 'Update password failed',
            description: err?.response ? err.response.data?.message : err.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <Modal
      title={
        <div>
          <p className='mb-0 text-xl font-bold text-[#007DC0]'>Update password</p>
        </div>
      }
      open={isModalOpen}
      onOk={handleUpdateProfile}
      onCancel={handleClose}
      okText='Save'
      loading={loading}
      centered
    >
      <div>
        <div className='flex w-full justify-center'>
          <p className='mb-2'>Please enter your information to reset your password.</p>
        </div>
        <div className='pt-5'>
          <div>
            <p className='mb-2'>
              Old password <span className='text-red-500'>(*)</span>
            </p>
            <Input.Password
              placeholder='Please enter old password...'
              className='mb-2 w-full'
              size='large'
              onChange={e => {
                setOldPassword(e.target.value);
                setErrorOldPass(validatePass(e.target.value, 'Old password'));
              }}
              value={oldPassword}
              required
            />
            {errorOldPass && <p className='mb-0 italic text-red-500'>{errorOldPass}</p>}
          </div>
          <div className='flex flex-col gap-2 pt-2 sm:flex-row'>
            <div>
              <p className='mb-2'>
                New password <span className='text-red-500'>(*)</span>
              </p>
              <Input.Password
                placeholder='New password...'
                className='mb-2 w-full'
                size='large'
                onChange={e => {
                  setNewPassword(e.target.value);
                  setErrorNewPass(validatePass(e.target.value, 'New password'));
                }}
                value={newPassword}
              />
            </div>
            <div>
              <p className='mb-2'>
                Confirm new password <span className='text-red-500'>(*)</span>
              </p>
              <Input.Password
                placeholder='Confirm new password...'
                className='mb-2 w-full'
                size='large'
                onChange={e => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
            </div>
          </div>
          {errorNewPass && <p className='mb-0 italic text-red-500'>{errorNewPass}</p>}
          {errorConfirmPass && <p className='mb-0 italic text-red-500'>{errorConfirmPass}</p>}
          <div className='pt-2'>
            <Checkbox onChange={e => setChecked(e.target.checked)} checked={checked}>
              Do you want to log out from all devices?
            </Checkbox>
          </div>
        </div>
      </div>
    </Modal>
  );
}
